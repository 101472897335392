import React from "react"
import { Link } from "gatsby"
import Img from 'gatsby-image'
// ---------------------------------------------
import Revealer from "../components/revealer"
// ---------------------------------------------

const Articles = ({ articles }) => (
  <ul className="journal-index pad">
    {articles.map(({node}, i) => (
      <li className="span-4 inner gutters p1 no-bullets" key={`${node.slug}-${i}`}>
        <Revealer className="fs">
          <figure>
            <div className="outer">
              <p>
                <strong>{node.title}{" "}</strong>
                {node.description.description}
              </p>
              <h4 className="outer has-links">
                <span className="link">The Journal</span>, {node.publishedOn}
              </h4>
            </div>

            <div className="w100">
              <Img fluid={{ ...node.coverImage.thumbnail, aspectRatio: 0.77 }} />
            </div>

            {node.body && (
              <div className="pabs b0 w100 align-center guttersx4 innerx4">
                <Link to={`/journal/${node.slug}`} className="btn white">Continue reading</Link>
              </div>
            )}
          </figure>
        </Revealer>
      </li>
    ))}
  </ul>
)

export default Articles
